<template>
    <div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="flex flex-column align-items-center justify-content-center" style="width: 70rem; margin-top: 2rem">
            <div style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, #0f97c7 10%, rgba(33, 150, 243, 0) 30%)">
                <div class="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center" style="border-radius: 53px">
                    <h1 class="text-900 font-bold text-3xl lg:text-5xl mb-2">Solicitação de Produto instântaneo - SAS</h1>

                    <div class="w-full flex align-items-center py-5 border-300">
                        <div class="p-fluid formgrid grid w-full">
                            <Message icon="pi-exclamation-triangle" :closable="false" class="w-full field col-12">
                                Não cadastraremos soluções que entrem em conflito com as já existentes. Consultar o portfólio antes de fazer sua solicitação.
                            </Message>
                        </div>
                    </div>

                    <div class="w-full flex align-items-center" v-for="erro in erros" :key="erro">
                        <Message class="w-full" severity="error">{{ erro }}</Message>
                    </div>

                    <div class="w-full flex align-items-center py-5 border-300">
                        <div class="p-fluid formgrid grid w-full">
                            <Fieldset class="mb-5 w-full">
                                <template #legend>
                                    <div class="flex align-items-center text-primary">
                                        <span class="pi pi-user mr-2"></span>
                                        <span class="font-bold text-lg">Identificação</span>
                                    </div>
                                </template>
                                <div class="p-fluid formgrid grid">
                                    <div class="field col-12 md:col-6">
                                        <label for="email">E-mail<i class="p-error">*</i></label>
                                        <InputText id="email" type="email" v-model="email" :class="{ 'p-invalid': v$.email.$error }" />
                                        <small class="p-error" v-if="v$.email.$error">E-mail é obrigatório</small>
                                    </div>
                                    <div class="field col-12 md:col-6">
                                        <label for="email">E-mail (Gestor do projeto)<i class="p-error">*</i></label>
                                        <InputText id="email" type="email" v-model="emailGestor" :class="{ 'p-invalid': v$.emailGestor.$error }" />
                                        <small class="p-error" v-if="v$.emailGestor.$error">E-mail Gestor é obrigatório</small>
                                    </div>
                                </div>
                            </Fieldset>

                            <Fieldset class="mb-5 w-full" v-if="visualizarAbaProduto">
                                <template #legend>
                                    <div class="flex align-items-center text-primary">
                                        <span class="pi pi-shopping-cart mr-2"></span>
                                        <span class="font-bold text-lg">Produto</span>
                                    </div>
                                </template>
                                <div class="p-fluid formgrid grid">
                                    <div class="field col-12 md:col-12">
                                        <label for="evento">Nome do Evento<i class="p-error">*</i></label>
                                        <InputText id="nome" optionLabel="nome" v-model="evento" :class="{ 'p-invalid': v$.evento.$error }"></InputText>
                                        <small class="p-error" v-if="v$.evento.$error">Nome do evento é obrigatório</small>
                                    </div>
                                    <div class="field col-12 md:col-3">
                                        <label for="dataInicio">Data de início do evento<i class="p-error">*</i></label>
                                        <Calendar id="dataInicio" v-model="dataInicio" :class="{ 'p-invalid': v$.dataInicio.$error }" showIcon />
                                        <small class="p-error" v-if="v$.dataInicio.$error">Data de início é obrigatória</small>
                                    </div>
                                    <div class="field col-12 md:col-3">
                                        <label for="dataFim">Data de fim do evento<i class="p-error">*</i></label>
                                        <Calendar id="dataFim" v-model="dataFim" :class="{ 'p-invalid': v$.dataFim.$error }" showIcon />
                                        <small class="p-error" v-if="v$.dataFim.$error">Data de início é obrigatória</small>
                                    </div>
                                    <div class="field col-12 md:col-3">
                                        <label for="dataFim">Horário do evento<i class="p-error">*</i></label>
                                        <Calendar id="dataFim" v-model="horario" :class="{ 'p-invalid': v$.horario.$error }" showIcon timeOnly />
                                        <small class="p-error" v-if="v$.horario.$error">Horário é obrigatória</small>
                                    </div>
                                    <div class="field col-12 md:col-3">
                                        <label for="preco">Preço<i class="p-error">*</i></label>
                                        <InputNumber v-model="preco" inputId="preco" mode="currency" currency="BRL" locale="pt-BR" />
                                        <small class="p-error" v-if="v$.preco.$error">Preço é obrigatório</small>
                                    </div>

                                    <Divider />

                                    <div class="field col-12 md:col-6">
                                        <label for="projeto">Projeto<i class="p-error">*</i></label>
                                        <InputText id="projeto" v-model="projeto" :class="{ 'p-invalid': v$.projeto.$error }"></InputText>
                                        <small class="p-error" v-if="v$.projeto.$error">Projeto é obrigatório</small>
                                    </div>
                                    <div class="field col-12 md:col-6">
                                        <label for="gestor">Nome do Gestor do Projeto<i class="p-error">*</i></label>
                                        <InputText id="gestor" v-model="gestor" :class="{ 'p-invalid': v$.gestor.$error }"></InputText>
                                        <small class="p-error" v-if="v$.gestor.$error">Gestor é obrigatório</small>
                                    </div>

                                    <div class="field col-12 md:col-12">
                                        <label for="unidade">Unidade do SEBRAE<i class="p-error">*</i></label>
                                        <Dropdown
                                            id="unidade"
                                            optionLabel="nome"
                                            v-model="unidadeSelecionada"
                                            filter
                                            :options="unidades"
                                            :class="{ 'p-invalid': v$.unidadeSelecionada.$error }"
                                        ></Dropdown>
                                        <small class="p-error" v-if="v$.unidadeSelecionada.$error">Unidade é obrigatória</small>
                                    </div>

                                    <Divider />

                                    <div class="field col-12 md:col-6">
                                        <label for="material">Selecione material didático<i class="p-error">*</i></label>
                                        <Dropdown
                                            id="material"
                                            optionLabel="nome"
                                            v-model="materialSelecionado"
                                            filter
                                            :options="materiais"
                                            :class="{ 'p-invalid': v$.materialSelecionado.$error }"
                                        ></Dropdown>
                                        <small class="p-error" v-if="v$.materialSelecionado.$error">Material é obrigatório</small>
                                    </div>
                                    <!-- <div class="field col-12 md:col-6">
                                        <label for="contrato">Selecione Contrato<i class="p-error">*</i></label>
                                        <Dropdown
                                            id="contrato"
                                            optionLabel="nome"
                                            v-model="contratoSelecionado"
                                            filter
                                            :options="contratos"
                                            :class="{ 'p-invalid': v$.contratoSelecionado.$error }"
                                        ></Dropdown>
                                        <small class="p-error" v-if="v$.contratoSelecionado.$error">Contrato é obrigatório</small>
                                    </div> -->
                                    <div class="field col-12 md:col-6">
                                        <label for="modalidade">Modalidade do Evento<i class="p-error">*</i></label>
                                        <Dropdown
                                            id="evento"
                                            optionLabel="nome"
                                            v-model="modalidadeSelecionada"
                                            filter
                                            :options="modalidades"
                                            :class="{ 'p-invalid': v$.modalidadeSelecionada.$error }"
                                        ></Dropdown>
                                        <small class="p-error" v-if="v$.modalidadeSelecionada.$error">Modalidade</small>
                                    </div>
                                    <div class="field col-12 md:col-6">
                                        <label for="evento">Tipo do evento<i class="p-error">*</i></label>
                                        <Dropdown
                                            id="evento"
                                            optionLabel="nome"
                                            v-model="eventoSelecionado"
                                            filter
                                            :options="eventos"
                                            :class="{ 'p-invalid': v$.eventoSelecionado.$error }"
                                        ></Dropdown>
                                        <small class="p-error" v-if="v$.eventoSelecionado.$error">Evento é obrigatório</small>
                                    </div>
                                    <div class="field col-12 md:col-6">
                                        <label for="tema">Tema Principal (até duas opções)<i class="p-error">*</i></label>
                                        <MultiSelect
                                            id="tema"
                                            optionLabel="nome"
                                            v-model="temaSelecionado"
                                            filter
                                            :options="temas"
                                            :class="{ 'p-invalid': v$.temaSelecionado.$error }"
                                        ></MultiSelect>
                                        <small class="p-error" v-if="v$.temaSelecionado.$error">Tema é obrigatório</small>
                                    </div>
                                    <div class="field col-12 md:col-12">
                                        <label for="complexidadeSelecionada">Complexidade da solução<i class="p-error">*</i></label>
                                        <Dropdown
                                            id="complexidadeSelecionada"
                                            optionLabel="nome"
                                            v-model="complexidadeSelecionada"
                                            filter
                                            :options="complexidades"
                                            :class="{ 'p-invalid': v$.complexidadeSelecionada.$error }"
                                        ></Dropdown>
                                        <small class="p-error" v-if="v$.complexidadeSelecionada.$error">Complexidade é obrigatório</small>
                                    </div>

                                    <div class="field col-12 md:col-12">
                                        <label for="compexidade">Descrição do Argumento de venda. <i class="p-error">*</i></label>
                                        <Textarea v-model="argumentoVenda" rows="5" :class="{ 'p-invalid': v$.argumentoVenda.$error }" />
                                        <small class="p-error" v-if="v$.argumentoVenda.$error">Argumento é obrigatório</small>
                                    </div>

                                    <div class="field col-12 md:col-3">
                                        <label for="carga">Carga Horária<i class="p-error">*</i></label>
                                        <InputMask
                                            v-model="cargaHoraria"
                                            mask="99:99:99"
                                            placeholder="h:min:s"
                                            :class="{ 'p-invalid': v$.cargaHoraria.$error }"
                                            aria-describedby="mask-error"
                                        />
                                        <small class="p-error" v-if="v$.cargaHoraria.$error">Carga Horária é obrigatória</small>
                                    </div>

                                    <div class="field col-12 md:col-6">
                                        <label for="geraCertificado">Irá gerar certificado<i class="p-error">*</i></label>
                                        <div class="flex flex-row gap-3">
                                            <div v-for="opcao in certificadoOpcoes" :key="opcao.key" class="flex align-items-center mr-3">
                                                <RadioButton
                                                    v-model="geraCertificado"
                                                    :inputId="opcao.key"
                                                    name="dynamic"
                                                    :value="opcao.key"
                                                    :class="{ 'p-invalid': v$.geraCertificado.$error }"
                                                />
                                                <label :for="opcao.key" class="ml-2">{{ opcao.name }}</label>
                                            </div>
                                        </div>
                                        <small class="p-error" v-if="v$.geraCertificado.$error">Opção é obrigatória</small>
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                    </div>
                    <div class="w-full flex align-items-end justify-content-end" v-if="!visualizarAbaProduto">
                        <Button class="justify-content-end" label="Próximo" @click.stop="validarProximo()"></Button>
                    </div>
                    <div class="w-full flex align-items-center py-5 border-300 border-bottom-1" v-if="visualizarAbaProduto">
                        <Button class="w-full" label="Enviar" @click.stop="validarEnvio()"></Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Dialog v-model:visible="visualizarModal" modal :closable="false" :style="{ width: '50vw' }">
        <div class="card">
            <div class="text-3xl text-900 mb-4 font-bold">⚠️ Informativo</div>
            <p class="line-height-3 text-lg mb-4">
                Não cadastraremos soluções que entrem em conflito com as já existentes. Consultar o portfólio antes de fazer sua solicitação. Para adequação de
                solução, entre em contato com a UDS no link indicado: <a>https://forms.office.com/r/wuE9n0d1rM</a>
            </p>
            <div class="text-900 mb-4 font-semibold">
                Caso queira realizar algum tipo de evento abaixo, favor utilizar o código na vinculação do seu evento.
            </div>
            <ul class="text-ml p-0 my-0 ml-5">
                <li class="mb-3 line-height-1">102000080599 - Seminário</li>
                <li class="mb-3 line-height-1">102000062536 - Feira</li>
                <li class="mb-3 line-height-1">102000062554 - Missão Empresarial</li>
                <li class="mb-3 line-height-1">102000062541 - Caravana</li>
                <li class="mb-3 line-height-1">102000062551 - Rodada de Negócio</li>
            </ul>
            <Message icon="pi-exclamation-triangle" :closable="false" class="w-full field col-12">
                O prazo da UDS para criação do produto real no SAS é de até 48h.
            </Message>
        </div>

        <div class="flex justify-content-start mt-4">
            <div class="flex align-items-start">
                <Checkbox v-model="confirmaLeitura" :binary="true" />
                <label for="ingredient1" class="ml-2 font-bold"> Confirmo que li os requisitos acima. </label>
            </div>
        </div>
        <template #footer>
            <Button label="Voltar" icon="pi pi-times" class="p-button-text" @click="visualizarModal = false" outlined />
            <Button label="Próximo" icon="pi pi-check" @click="confirmaVisualizacao()" autofocus :disabled="!confirmaLeitura" />
        </template>
    </Dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import chamadoService from './service';

export default {
    data() {
        return {
            visualizarAbaProduto: false,
            visualizarModal: false,
            confirmaLeitura: false,

            email: '',
            emailGestor: '',
            materialSelecionado: '',
            contratoSelecionado: '',
            eventoSelecionado: '',
            evento: '',
            modalidadeSelecionada: '',
            temaSelecionado: '',
            complexidadeSelecionada: '',
            argumentoVenda: '',
            projeto: '',
            gestor: '',
            dataInicio: '',
            dataFim: '',
            preco: null,
            horario: 0,
            unidadeSelecionada: '',
            geraCertificado: null,
            cargaHoraria: '',

            certificadoOpcoes: [
                { name: 'Sim', key: true },
                { name: 'Não', key: false },
            ],
            complexidades: [
                {
                    nome: 'Básica',
                },
                {
                    nome: 'Intermediária',
                },
                {
                    nome: 'Avançada',
                },
            ],
            modalidades: [
                {
                    nome: 'Presencial',
                },
                {
                    nome: 'Remoto',
                },
                {
                    nome: 'Híbrido',
                },
            ],
            eventos: [
                {
                    nome: 'Curso',
                },
                {
                    nome: 'Palestra',
                },
                {
                    nome: 'Oficina',
                },
                {
                    nome: 'Outro',
                },
            ],
            materiais: [
                {
                    nome: 'Material Próprio',
                },
                {
                    nome: 'Material de Parceiro',
                },
            ],
            contratos: [
                {
                    nome: 'SENAC',
                },
                {
                    nome: 'SENAI',
                },
                {
                    nome: 'SESI',
                },
                {
                    nome: 'SEST-SENAT',
                },
            ],
            temas: [
                { nome: 'Cooperação' },
                { nome: 'Empreendedorismo' },
                { nome: 'Finanças' },
                { nome: 'Inovação' },
                { nome: 'Leis' },
                { nome: 'Mercado e Vendas' },
                { nome: 'Organização' },
                { nome: 'Pessoas' },
                { nome: 'Planejamento' },
            ],
            unidades: [
                { nome: 'Unidade Sertão do São Francisco' },
                { nome: 'Unidade Sertão do Araripe' },
                { nome: 'Unidade Agreste Central e Setentrional' },
                { nome: 'Unidade Agreste Meridional' },
                { nome: 'Unidade Sertão Central, Moxotó, Pajeú e Itaparic' },
                { nome: 'Unidade RMR' },
                { nome: 'Unidade LED - Laboratório de Estratégias Digitais de Negócios' },
                { nome: 'Unidade de Relacionamento' },
                { nome: 'Unidade da Zona da Mata' },
            ],
            erros: [],
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            email: { required },
            emailGestor: { required },
            materialSelecionado: { required },
            // contratoSelecionado: { required },
            eventoSelecionado: { required },
            evento: { required },
            modalidadeSelecionada: { required },
            temaSelecionado: { required },
            complexidadeSelecionada: { required },
            argumentoVenda: { required },
            projeto: { required },
            gestor: { required },
            dataInicio: { required },
            dataFim: { required },
            preco: { required },
            horario: { required },
            unidadeSelecionada: { required },
            geraCertificado: { required },
            cargaHoraria: { required },
        };
    },
    methods: {
        async validarEnvio() {
            this.v$.$touch();
            if (this.v$.$invalid) return;
            this.validarSolicitacao();
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('formProdutoReal');
            this.enviar(token);
        },
        validarSolicitacao() {
            if (this.eventoSelecionado.nome === 'Palestra') {
                if (this.dataFim > this.dataInicio) {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                    this.erros.push('Data de início não pode ser maior que a data de fim pois a solução é uma palestra, deve conter até duas horas de duração');
                }

                if (this.cargaHoraria < '02:00:00') {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                    this.erros.push('Horário não pode ser menor que 02:00:00 pois a solução é uma palestra, deve conter no minimo duas horas de duração');
                }

                return;
            }

            if (this.eventoSelecionado.nome === 'Oficina') {
                if (this.cargaHoraria < '03:00:00' && this.modalidadeSelecionada.nome === 'Presencial') {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                    this.erros.push('Horário não pode ser menor que 02:00:00 pois a solução é uma oficina, deve conter mais de duas horas de duração');

                    return;
                }

                if (this.cargaHoraria < '02:00:00' && this.modalidadeSelecionada.nome === 'Remoto') {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                    this.erros.push('Horário não pode ser menor que 02:00:00 pois a solução é uma oficina, deve conter mais de duas horas de duração');

                    return;
                }

                if (this.dataFim < this.dataInicio) {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                    this.erros.push(
                        'Data de início não pode ser maior que a data de fim pois a solução é uma oficina, deve conter mais de duas horas de duração'
                    );

                    return;
                }

                if (this.cargaHoraria > '09:00:00') {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                    this.erros.push('Horário não pode ser maior que 09:00:00 pois a solução é uma oficina, deve conter mais de duas horas de duração');

                    return;
                }
            }
        },
        validarProximo() {
            this.v$.$touch();
            if (this.v$.email.$invalid && this.v$.emailGestor.$invalid) return;
            this.abrirModal();
        },
        abrirModal() {
            this.visualizarModal = true;
        },
        confirmaVisualizacao() {
            this.v$.$reset();
            this.visualizarModal = false;
            this.visualizarAbaProduto = true;
        },
        enviar(token) {
            const solicitacao = {
                email: this.email,
                emailGestor: this.emailGestor,
                material: this.materialSelecionado.nome,
                // contrato: this.contratoSelecionado.nome,
                contreato: 'SEBRAE',
                evento: this.eventoSelecionado.nome,
                eventoNome: this.evento,
                modalidade: this.modalidadeSelecionada.nome,
                temas: this.temaSelecionado.map((tema) => tema.nome).join(','),
                complexidade: this.complexidadeSelecionada.nome,
                argumentoVenda: this.argumentoVenda,
                projeto: this.projeto,
                gestor: this.gestor,
                dataInicio: this.dataInicio,
                dataFim: this.dataFim,
                preco: this.preco,
                horario: this.$moment(this.horario).format('HH:mm'),
                unidade: this.unidadeSelecionada.nome,
                geraCertificado: this.geraCertificado,
                cargaHoraria: this.cargaHoraria,
                token: token,
            };

            this.$store.dispatch('addRequest');
            chamadoService
                .solicitarProdutoReal(solicitacao)
                .then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Solicitação',
                        detail: 'Solicitação enviada com sucesso',
                        life: 3000,
                    });
                    this.$router.push({ name: 'sol_centralChamados' });
                })
                .catch((error) => {
                    error.response.data.errors.mensagens.forEach((e) => {
                        this.erros.push(e);
                    });
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },
    watch: {
        confirmaLeitura() {
            this.confirmaLeitura ? (this.confirmaLeitura = true) : (this.confirmaLeitura = false);
        },
    },
};
</script>
